import { api } from '../../config';
import { history, store } from '../../index';
import { RESTColorMap as color } from '../style/colors';
import { setAbortController } from '../store/actions/search';

const INVALID_TOKEN = 'Token ungültig';
const CSS_FETCH_INFO_STYLE = method => `font-style: italic; color: ${color.get(method)}`;

const logFetchResolve = (method, string, type, data, response) => {
    console.groupCollapsed(
        '%c' + string + ' ' + method + ' ' + response.status + ': ' + response.url,
        CSS_FETCH_INFO_STYLE(method)
    );
    console.log('type: %s', type);
    console.log('data: %o', data);
    console.log(response);
    console.groupEnd();
};

// TODO reicht das, wenn der token hier geholt wird?
export const getToken = () => {
    // token ist vorhanden
    if (localStorage.getItem('token')) {
        let timeNow = Number.parseInt(new Date().getTime() / 1000, 10);
        let timeExp = Number.parseInt(localStorage.getItem('exp_maxTime'), 10);
        // token ist noch gültig
        if (timeExp > timeNow) {
            return localStorage.getItem('token');
        }
    }
    // kein token oder Zeit ist abgelaufen
    return '';
};

export const createItem = async (type, body = {}, push = true) => {
    //Überprüfung, ob token vorhanden ist befindet sich in der EditHoc.componentWillMount
    let url = api + '/api/v1/' + type;
    console.groupCollapsed('%c POST ' + url, CSS_FETCH_INFO_STYLE('POST'));
    console.log('body: %o', body);
    console.groupEnd();
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': getToken(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    // TODO aktuell unklar ob die Statusabfrage funktioniert, wenn der fetch failed - bei 503 Errors ist kein json() möglich
    if (response.status === 502)
        throw new Error('Fehler beim Erstellen:\nFataler Fehler - Backend ist abgestürzt');
    const data = await response.json();
    logFetchResolve('POST', '', type, data, response);
    if (!response.ok && data.message) throw new Error(data.message);
    if (!response.ok && !data.message)
        throw new Error('Fehler beim Erstellen:\nFehler, aber keine Message vom Server!');
    if (push) history.push(`/edit/${type}/${data._id}`);
    return data;
};

export const getOperatorItem = async (type, query, limit, skip) => {
    if (getToken() !== '') {
        let url =
            `${api}/api/v1/${type}/query?` +
            `q=${encodeURIComponent(JSON.stringify(query))}&limit=${JSON.stringify(limit)}&skip=${JSON.stringify(
                skip
            )}`;
        console.info('%cGET ' + url, CSS_FETCH_INFO_STYLE('GET'));
        const abortController = new AbortController();
        store.dispatch(setAbortController(abortController));
        const data = await fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': getToken(),
                'Content-Type': 'application/json',
            },
            signal: abortController.signal,
        })
            .then(async res => {
                const data = await res.json();
                logFetchResolve('GET', '', type, data, res);
                if (data.error) throw new Error(data.message);
                if (!res.ok && data.message) throw new Error(data.message);
                if (!res.ok && !data.message)
                    throw new Error('Fehler, aber keine Message vom Server!');
                return data;
            })
            .catch();
        return data;
    } else {
        history.push('/login/forceLogout');
        // TODO zu langsam????
        //muss ein leeres array zurückgeben, damit keine Fehler geworfen werden
        //fehler kommt auf, da forcedLogout zu langsam ist
        return [];
    }
};

export const getAllAttributesItem = async (type, params = {}) => {
    if (getToken() !== '') {
        const queryParams = Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join('&');
        let url = `${api}/api/v1/${type}/allAttributes?${queryParams}`;
        console.info('%cGET ' + url, CSS_FETCH_INFO_STYLE('GET'));
        const abortController = new AbortController();
        store.dispatch(setAbortController(abortController));
        const data = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': getToken(),
            },
            signal: abortController.signal,
        })
            .then(async res => {
                const data = await res.json();
                logFetchResolve('GET', '', type, data, res);
                if (data.error) throw new Error(data.message);
                if (!res.ok && data.message) throw new Error(data.message);
                if (!res.ok && !data.message)
                    throw new Error('Fehler, aber keine Message vom Server!');
                return data;
            })
            .catch();
        return data;
    } else {
        history.push('/login/forceLogout');
        // TODO zu langsam????
        //muss ein leeres array zurückgeben, damit keine Fehler geworfen werden
        //fehler kommt auf, da forcedLogout zu langsam ist
        return [];
    }
};

export const getItem = async (type, id, params = '', count = false) => {
    if (getToken() !== '') {
        let url = `${api}/api/v1/${type}`;
        if (id) url += `/${id}`;
        params += `&count=${count}`;
        url += `?${params}`;
        console.info('%cGET ' + url, CSS_FETCH_INFO_STYLE('GET'));
        // ThBw/issues/550
        const abortController = new AbortController();
        // save the controller into our redux store
        store.dispatch(setAbortController(abortController));
        const data = await fetch(url, {
            method: 'GET',
            headers: { 'x-access-token': getToken() },
            signal: abortController.signal,
        })
            .then(async res => {
                const data = await res.json();
                logFetchResolve('GET', '', type, data, res);
                if (data.error) throw new Error(data.message);
                if (!res.ok && data.message) throw new Error(data.message);
                if (!res.ok && !data.message)
                    throw new Error('Fehler, aber keine Message vom Server!');
                return data;
            })
            .catch();
        return data;
    } else {
        history.push('/login/forceLogout');
        // TODO zu langsam????
        //muss ein leeres array zurückgeben, damit keine fehler geworfen werden
        //fehler kommt auf, da forcedLogout zu langsam ist
        return [];
    }
};

export const count = async (type, id, params = '') => {
    const token = getToken();
    if (token && token !== '') {
        let url = `${api}/api/v1/${type}/count`;
        if (id) url += `/${id}`;
        if (params) url += `?${params}`;
        console.info('%ccount GET ' + url, CSS_FETCH_INFO_STYLE('GET'));
        const response = await fetch(url, {
            method: 'GET',
            headers: { 'x-access-token': token },
        });
        const data = await response.json();
        logFetchResolve('GET', 'count', type, data, response);
        if (!response.ok && data.message) throw new Error(data.message);
        if (!response.ok && !data.message)
            throw new Error('Fehler, aber keine Message vom Server!');
        return data;
    } else {
        history.push('/login/forceLogout');
        //muss ein leeres array zurückgeben, damit keine fehler geworfen werden
        //fehler kommt auf, da forcedLogout zu langsam ist
        return [];
    }
};

export const getRef = async (type, id) => {
    if (getToken() !== '') {
        let url = `${api}/api/v1/references/${type}/${id}`;
        console.info('%c getRef GET ' + url, CSS_FETCH_INFO_STYLE('GET'));
        const response = await fetch(url, {
            method: 'GET',
            headers: { 'x-access-token': getToken() },
        });
        const data = await response.json();
        logFetchResolve('GET', 'getRef', type, data, response);
        if (!response.ok && data.message) throw new Error(data.message);
        if (!response.ok && !data.message)
            throw new Error('Fehler, aber keine Message vom Server!');
        return data;
    } else {
        history.push('/login/forceLogout');
    }
};

export const fastsearch = async (type, params) => {
    if (getToken() !== '') {
        try {
            let url = `${api}/api/v1/${type}/fastsearch`;
            params += `&count=true`;
            url += `?${params}`;
            console.info('%c fastsearch GET ' + url, CSS_FETCH_INFO_STYLE('GET'));
            const response = await fetch(url, {
                method: 'GET',
                headers: { 'x-access-token': getToken() },
            });
            const data = await response.json();
            logFetchResolve('GET', 'fastsearch', type, data, response);
            if (!response.ok) console.error(data.message);
            return data;
        } catch (e) {
            //TODO add proper Error handling!
            console.error(e);
            return {};
        }
    } else {
        history.push('/login/forceLogout');
        //muss ein leeres array zurückgeben, damit keine Fehler geworfen werden
        //fehler kommt auf, da forcedLogout zu langsam ist
        return [];
    }
};

export const addFavorite = async (userId, kind, objId) => {
    if (getToken() !== '') {
        if (userId && objId) {
            const url = `${api}/api/v1/mitarbeiter/${userId}/addFav/${kind}/${objId}`;
            console.info('%c addFavorite PUT ' + url, CSS_FETCH_INFO_STYLE('PUT'));
            const response = await fetch(url, {
                method: 'PUT',
                headers: { 'x-access-token': getToken() },
            });
            const data = await response.json();
            logFetchResolve('PUT', 'addFavorite', `${userId} ${kind} ${objId}`, data, response);
            if (!response.ok && data.message) console.error(data.message);
            if (!response.ok && !data.message)
                throw new Error('Fehler, aber keine Message vom Server!');
            return data;
        }
    }
};

export const removeFavorite = async (userId, objId) => {
    if (getToken() !== '') {
        if (userId && objId) {
            const url = `${api}/api/v1/mitarbeiter/${userId}/removeFav/${objId}`;
            console.info('%c removeFavorite PUT ' + url, CSS_FETCH_INFO_STYLE('PUT'));
            const response = await fetch(url, {
                method: 'PUT',
                headers: { 'x-access-token': getToken() },
            });
            const data = await response.json();
            logFetchResolve('PUT', 'removeFavorite', `${userId} ${objId}`, data, response);
            if (!response.ok && data.message) throw new Error(data.message);
            if (!response.ok && !data.message)
                throw new Error('Fehler, aber keine Message vom Server!');
            return data;
        }
    }
};

export const saveItem = async (type, id, item) => {
    if (getToken() !== '') {
        if (id && type) {
            const url = `${api}/api/v1/${type}/${id}`;
            console.groupCollapsed('%c PUT ' + url, CSS_FETCH_INFO_STYLE('PUT'));
            console.log('body: %o', item);
            console.groupEnd();
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': getToken(),
                },
                body: JSON.stringify(item),
            });
            const data = await response.json();
            logFetchResolve('PUT', '', type, data, response);
            if (!response.ok && data.message) throw new Error(data.message);
            if (!response.ok && !data.message)
                throw new Error('Fehler, aber keine Message vom Server!');
            return data;
        } else if (!id) {
            // no id => create item
            return createItem(type, item);
        } else {
            throw new Error('Fehler beim Erstellen');
        }
    } else {
        history.push('/login/forceLogout');
        throw new Error(INVALID_TOKEN);
    }
};

export const deleteItem = async (type, id) => {
    //Überprüfung, ob token vorhanden ist befindet sich in der Ref.componentWillMount
    try {
        const url = `${api}/api/v1/${type}/${id}`;
        console.info('%cDELETE ' + url, CSS_FETCH_INFO_STYLE('DELETE'));
        const response = await fetch(url, {
            method: 'DELETE',
            headers: { 'x-access-token': getToken() },
        });
        const data = await response.json();
        logFetchResolve('DELETE', '', type, data, response);
        if (!response.ok) console.error(data.message);
        return response.status;
    } catch (e) {
        //TODO add proper Error handling!
        console.error(e);
        return {};
    }
};

export const loadTypeList = async type => {
    try {
        const url = `${api}/api/v1/${type}/typelist`;
        console.info('%cGET ' + url, CSS_FETCH_INFO_STYLE('GET'));
        const response = await fetch(url, {
            method: 'GET',
            headers: { 'x-access-token': getToken() },
        });
        const data = await response.json();
        logFetchResolve('GET', '', type, data, response);
        if (!response.ok) console.error(data.message);
        return data;
    } catch (e) {
        //TODO add proper Error handling!
        console.error(e);
        return [];
    }
};

export const loadSchema = async type => {
    try {
        const url = `${api}/api/v1/${type}/schema`;
        console.info('%cGET ' + url, CSS_FETCH_INFO_STYLE('GET'));
        const response = await fetch(url, {
            method: 'GET',
            headers: { 'x-access-token': getToken() },
        });
        const data = await response.json();
        logFetchResolve('GET', '', type, data, response);
        if (!response.ok) console.error(data.message);
        return data;
    } catch (e) {
        //TODO add proper Error handling!
        console.error(e);
        return [];
    }
};

export const loadAdvSearchSchema = async model => {
    const url = `${api}/api/v1/${model}/advSearch`;
    console.info('%cGET ' + url, CSS_FETCH_INFO_STYLE('GET'));
    const response = await fetch(url, {
        method: 'GET',
        headers: { 'x-access-token': getToken() },
    });
    const data = await response.json();
    return data;
};
