import { get } from 'lodash';
import { count, getAllAttributesItem, getItem, getOperatorItem } from '../../service/fetch';

import {
    ABORT_FETCH,
    INIT_ATTRIBUTES_SEARCH,
    SEARCH_CHANGE_ITEMS,
    SEARCH_CHANGE_PAGE,
    SEARCH_JUMP_PAGE,
    SEARCH_RESET_PAGE,
    SEARCH_CHANGE_SORT,
    SEARCH_END_LOADING,
    SEARCH_RESET,
    SEARCH_SET_COUNT,
    SEARCH_SET_LIMIT,
    SEARCH_START_LOADING,
    SEARCH_TOGGLE_SHOW_EYE,
    SET_ABORT_CONTROLLER,
} from '../constants/search';
import { SET_SCHEMA } from '../constants/item';

const serializeObject = (obj, path = '', result = []) => {
    for (let prop in obj) {
        // only serializing own properties and ignore inherited
        if (!obj.hasOwnProperty(prop)) {
            continue;
        }
        // if prop is not "v", prop should be an object than needs to be serialized
        if (prop !== 'v' && obj[prop]) {
            serializeObject(obj[prop], path + prop + '.', result);
            continue;
        }
         // special handling in case v is an array
        if (prop === 'v' && Array.isArray(obj[prop])){
            for (let i of obj[prop])
                result.push(path + prop + '=' + i)
            continue;
        }
        // if prop is v, it should contain a value
        if (prop === 'v' && obj[prop]) {
            if (obj[prop]._id) result.push(path + prop + '=' + obj[prop]._id);
            else result.push(path + prop + '=' + obj[prop]);
        }
    }
    return result;
};

export const changeSort = name => {
    return dispatch => {
        dispatch({
            type: SEARCH_CHANGE_SORT,
            name,
        });
    };
};

export const changeSortAndLoad = name => {
    return dispatch => {
        dispatch({
            type: SEARCH_CHANGE_SORT,
            name,
        });
        dispatch(getDataFromApi());
    };
};

export const getDataFromApi = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const { page, limit, sort, asc } = state.search;
        let type = state.router.location.pathname.split('/')[2];
        if (type === "kurpfalz1") type = "brief";
        if (type === "strassburg1") type = "brief";
        if (type === "wuerttemberg1") type = "brief";
        if (type === "wuerttemberg2") type = "brief";
        if (type === "hauptpersonen") type = "person";

        let query = '';
        serializeObject(state.item).forEach(p => (query += p + '&'));
        // add sort + asc to query
        query += `sort=${sort}&asc=${asc}&`;

        // add limit + skip to every request
        query += `limit=${limit}&skip=${page * limit}`;

        dispatch(abortPreviousFetch());
        dispatch(startLoadingSearch());
        const searchCount = await count(type, null, query);
        dispatch(changeItems(await getItem(type, null, query)));
        dispatch(setCount(searchCount));
        dispatch(endLoadingSearch());
    };
};

export const initAttributesSearch = typeList => ({ type: INIT_ATTRIBUTES_SEARCH, typeList });

export const startAllAttributesSearch = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const type = state.router.location.pathname.split('/')[2];
        dispatch(abortPreviousFetch());
        const { page, limit } = state.search;
        const skip = page * limit;
        let dropdownKey = get(state.item.$attributes, 'key.v');
        if (!dropdownKey) dropdownKey = 'Freie Suche'; // default value
        let data = null;
        let params = { limit, skip };
        if (dropdownKey !== 'Freie Suche' && dropdownKey !== 'Zeit') {
            const id = get(state.item.$attributes, 'value.v._id');
            if(id) {
                // user selected something in fast search
                params = { ...params, id };
            }
            const modelType = state.search.availableTypes.find(obj => obj[1] === dropdownKey)[0];
            params = { ...params, modelType };
            dispatch(startLoadingSearch());
            data = await getAllAttributesItem(type, params);
        } else {
            const value = get(state.item.$attributes, 'value.v');
            if(value) {
                params = { ...params, value };
            }
            // user wrote something into the Freitext or Zeit input field
            if(dropdownKey === 'Freie Suche')
                params = { ...params, freitext: true };
            else if (dropdownKey === 'Zeit')
                params = { ...params, zeit: true };
            dispatch(startLoadingSearch());
            data = await getAllAttributesItem(type, params);
        }
        dispatch(changeItems(data.documents));
        dispatch(setCount(data.count));
        dispatch(endLoadingSearch());
    };
};

/**
 * This function parses the redux state which is created during interaction with
 * the React components (Dropdowns, Inputs, ...) into a query.
 * The redux state should be an array consisting of objects with the following keys:
 * 'key', 'value', 'binary', 'unary'
 * Therefore, the state should for example look like this:
 * [
 *   { key: 'Sprache', value: 'dt.', unary: 'NICHT', binary: 'UND' },
 *   { key: 'Sprache', value: 'lat.', unary: 'NICHT' },
 * ]
 *
 * Notice how the last item should not have a binary key since it would not make sense.
 * The binary keys always connect the current item with the following item!
 *
 * NOTE: when testing this function, copy it into a test file.
 *   I couldn't import it from the test file due to following error:
 *
 * /home/ekzyis/Programming/ThBw/client/node_modules/rc-tooltip/es/index.js:1
 * ({"Object.<anonymous>":function(module,exports,require,__dirname,__filename,global,jest){import Tooltip from './Tooltip';
 *                                                                                          ^^^^^^
 *  SyntaxError: Cannot use import statement outside a module
 *
 *    4 | import Underline from '../style/Underline';
 *    5 | import styled from 'styled-components';
 *  > 6 | import Tooltip from 'rc-tooltip/es';
 *      | ^
 *    7 | import Row from '../style/Row';
 *    8 |
 *    9 | const Space = styled.div`
 *
 *    at ScriptTransformer._transformAndBuildScript (node_modules/jest-runtime/build/script_transformer.js:403:17)
 *    at Object.<anonymous> (src/client/inputs/LabelFocusHoc.js:6:1)
 *
 * @param state                     The redux state of the operator search.
 * @returns {{$and: [], $or: []}}
 */
const parseOpSearchStateIntoQuery = state => {
    const query = { $and: [], $or: [], $time: [] };
	const time = state.$period;
    const getOptions = item => {
		let tmp = {};
        if (item.wording && item.wording.v === 'ist vorhanden') {
			 tmp = { ...tmp, $hasValue: true };
		} else if (item.wording && item.wording.v === 'ist nicht vorhanden') {
			 tmp = { ...tmp, $hasValue: false };
		} else if (item.wording && item.wording.v === 'enthält nicht') {
			 tmp = { ...tmp, $ne: true };
		}
		return tmp;
    };
    if (state.$operator.length === 1) {
        const item = state.$operator[0];
        let options = getOptions(item);
        query.$and.push({ [item.key.v]: item.value.v, options });
    } else {
        state.$operator.forEach((item, i) => {
            if (i === 0)
            // skip first item
                return;
            const prevItem = state.$operator[i - 1];
            let prevOptions = getOptions(prevItem);
            const currItem = item;
            let currOptions = getOptions(currItem);
            // default binary operator is UND
            // TODO: REFACTOR
            //   this depends on the order of items on the dropdown in OpSearch thus is error-prone!
            let binaryOp = 'UND';
            if (prevItem.binary && prevItem.binary.v !== undefined) {
                binaryOp = prevItem.binary.v;
            }
            if (i === 1) {
                // add first two items
                if (binaryOp === 'UND') {
                    query.$and.push({ [prevItem.key.v]: prevItem.value.v, options: prevOptions });
                    query.$and.push({ [currItem.key.v]: currItem.value.v, options: currOptions });
                } else if (binaryOp === 'ODER') {
                    query.$or.push({ [prevItem.key.v]: prevItem.value.v, options: prevOptions });
                    query.$or.push({ [currItem.key.v]: currItem.value.v, options: currOptions });
                }
            } else {
                const binaryOp = prevItem.binary.v;
                if (binaryOp === 'UND') {
                    query.$and.push({ [currItem.key.v]: currItem.value.v, options: currOptions });
                } else if (binaryOp === 'ODER') {
                    query.$or.push({ [currItem.key.v]: currItem.value.v, options: currOptions });
                }
            }
        });
    }
    // transform Datierung values into format as they are stored in backend for better matching
    // for example: 19.05.1943 -> 19.5.1943
    const transformZeitValue = value => {
        return value.replace(/0([0-9])+\./g, '$1.');
    };
    const transformZeitObjectsInQuery = query => {
        return query.map(obj => {
            const key = Object.keys(obj)[0];
            const value = obj[key];
            if(key === 'Zeit') {
                return {
                    ...obj,
                    [key]: transformZeitValue(value),
                };
            } else {
                return obj;
            }
        });
    };
    query.$and = transformZeitObjectsInQuery(query.$and);
    query.$or = transformZeitObjectsInQuery(query.$or);

    const filterEntriesWithEmptyValueAndHasValueIsNotOption = query => {
        return query.filter(object => {
            const options = object.options;
            if (!Object.keys(options).includes('$hasValue')) {
                const key = Object.keys(object)[0];
                const value = object[key];
                // do not filter actual boolean values
                if(value === true || value === false) {
                    return true;
                }
                return !!value;
            } else return true;
        });
    };

    query.$and = filterEntriesWithEmptyValueAndHasValueIsNotOption(query.$and);
    query.$or = filterEntriesWithEmptyValueAndHasValueIsNotOption(query.$or);

	if(time) {
        const vonNotEmpty = !!get(time, "von.j.v");
        const bisNotEmpty = !!get(time, "bis.j.v");
        if(vonNotEmpty && bisNotEmpty){
            const von = new Date(Date.UTC(time.von.j.v, get(time, "von.m.v") ? time.von.m.v - 1 : 0, get(time, "von.t.v") ? time.von.t.v : 1));
            const bis = new Date(Date.UTC(time.bis.j.v, get(time, "bis.m.v") ? time.bis.m.v - 1 : 12, get(time, "bis.t.v") ? time.bis.t.v : 31));
            query.$time = [von, bis];
        }
        else if (vonNotEmpty && !bisNotEmpty){
            query.$time[0] = new Date(Date.UTC(time.von.j.v, get(time, "von.m.v") ? time.von.m.v - 1 : 0, get(time, "von.t.v") ? time.von.t.v : 1));
        }
        else if (!vonNotEmpty && bisNotEmpty){
            query.$time[1] = new Date(Date.UTC(time.bis.j.v, get(time, "bis.m.v") ? time.bis.m.v - 1 : 12, get(time, "bis.t.v") ? time.bis.t.v : 31));
        }
    }

    return query;
};

export const startOperatorSearch = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const type = state.router.location.pathname.split('/')[2];
        const query = parseOpSearchStateIntoQuery(state.item);
        const { page, limit } = state.search;
        const skip = page * limit;
        dispatch(abortPreviousFetch());
        dispatch(startLoadingSearch());
        const { documents, count } = await getOperatorItem(type, query, limit, skip);
        dispatch(changeItems(documents));
        dispatch(setCount(count));
        dispatch(endLoadingSearch());
    };
};

export const startLoadingSearch = () => ({ type: SEARCH_START_LOADING });

export const endLoadingSearch = () => ({ type: SEARCH_END_LOADING });

export const changeItems = items => ({ type: SEARCH_CHANGE_ITEMS, items });

export const changePageHOC = startSearch => value => {
    return dispatch => {
        dispatch({ type: SEARCH_CHANGE_PAGE, value });
        dispatch(startSearch());
    };
};

export const jumpPageHOC = startSearch => value => {
    return dispatch => {
        dispatch({ type: SEARCH_JUMP_PAGE, value });
        dispatch(startSearch());
    };
}

export const resetPage = () => {
    return dispatch => {
        dispatch({ type: SEARCH_RESET_PAGE });
        dispatch(getDataFromApi());
    };
};

export const changePage = changePageHOC(getDataFromApi);

export const setCount = count => ({ type: SEARCH_SET_COUNT, count });

export const setLimit = limit => ({ type: SEARCH_SET_LIMIT, limit});

export const resetSearch = () => ({ type: SEARCH_RESET });

export const toggleShowEye = path => ({ type: SEARCH_TOGGLE_SHOW_EYE, path });

export const setAbortController = controller => ({ type: SET_ABORT_CONTROLLER, controller });

export const abortPreviousFetch = () => ({ type: ABORT_FETCH });

export const setSchema = schema => ({ type: SET_SCHEMA, schema });
